import * as React from "react"
import Layout from "../components/layout"
import IndexPage from "../components/pageComponents/index"
import messages from "../i18n/de"

const IndexPageDe = ({ location }) => (
  <Layout location={location} messages={messages}>
    <IndexPage langKey="de" />
  </Layout>
)

export default IndexPageDe
